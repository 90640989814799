// src/features/shared/layout/GlobalLayout.tsx
import React from 'react';
import { Box, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import TopAppBar from './TopAppBar';

const drawerWidth = 240;

const GlobalLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <TopAppBar />
            <Toolbar /> {/* spacing under AppBar */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    // On desktop, ensure main content is offset by drawer width if drawer is permanent.
                    marginLeft: isMobile ? 0 : `${drawerWidth}px`,
                    transition: 'margin-left 0.3s ease-in-out',
                    boxSizing: 'border-box',
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default GlobalLayout;
