// src/features/shared/layout/TopAppBar.tsx
import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
    Box,
    useMediaQuery,
    useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '@app/hooks/useAuth';
import { logout } from '@api/firebase/firebaseAuth';
import { useTranslation } from 'react-i18next';
import { adminMenuItems, publicMenuItems } from '@shared/menu/menuNavigationItems';
import MainDrawer from '@shared/components/MainDrawer';

const TopAppBar: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const location = useLocation();
    const { currentUser, isAdmin } = useAuth();
    const { t } = useTranslation();
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error(t('logoutError'), error);
        }
    };

    const handleLoginClick = () => {
        navigate('/login');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };

    const isOnAdminSide = location.pathname.startsWith('/admin');
    const menuItems = isOnAdminSide ? adminMenuItems : publicMenuItems;

    return (
        <>
            <AppBar position="fixed" color="primary">
                <Toolbar>
                    {isMobile && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}

                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleLogoClick}
                        sx={{ mr: 2 }}
                        aria-label="logo"
                    >
                        <img
                            src="/android-chrome-192x192.png"
                            alt="Logo"
                            style={{ width: 40, height: 40, objectFit: 'contain' }}
                        />
                    </IconButton>

                    {!isMobile && (
                        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                            {t('appName')}
                        </Typography>
                    )}

                    <Box sx={{ flexGrow: 1 }} />

                    <IconButton
                        edge="end"
                        color="inherit"
                        href="https://www.facebook.com/profile.php?id=100063605919723"
                        target="_blank"
                        aria-label="Facebook"
                    >
                        <FacebookIcon />
                    </IconButton>
                    <IconButton
                        edge="end"
                        color="inherit"
                        href="https://www.instagram.com/lenkaszucbeauty/"
                        target="_blank"
                        aria-label="Instagram"
                    >
                        <InstagramIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 0.1 }} />

                    {currentUser && isAdmin && (
                        <IconButton
                            color="inherit"
                            onClick={() => navigate(isOnAdminSide ? '/' : '/admin')}
                            sx={{ mr: 2 }}
                            aria-label="admin-toggle"
                            title={isOnAdminSide ? t('goPublic') : t('goAdmin')}
                        >
                            <AdminPanelSettingsIcon />
                        </IconButton>
                    )}

                    {currentUser ? (
                        <Button color="inherit" onClick={handleLogout}>
                            {t('logout')}
                        </Button>
                    ) : (
                        <Button color="inherit" onClick={handleLoginClick}>
                            {t('login')}
                        </Button>
                    )}
                </Toolbar>
            </AppBar>

            <MainDrawer
                menuItems={menuItems}
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            />
        </>
    );
};

export default TopAppBar;
