// src/features/shared/components/MainDrawer.tsx
import React from 'react';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Box, useMediaQuery, useTheme } from '@mui/material';
import { MenuItem } from '@shared/menu/menuNavigationItems';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface MainDrawerProps {
    menuItems: MenuItem[];
    open: boolean;
    onClose: () => void;
}

const drawerWidth = 240;

const MainDrawer: React.FC<MainDrawerProps> = ({ menuItems, open, onClose }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // On desktop: permanent, on mobile: temporary
    const variant = isMobile ? 'temporary' : 'permanent';

    return (
        <Drawer
            variant={variant}
            open={isMobile ? open : true} // On desktop, always open; on mobile, controlled by `open`
            onClose={onClose}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    top: '64px',
                },
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                <List>
                    {menuItems.map((item) => (
                        <ListItem key={item.text} disablePadding>
                            <ListItemButton
                                selected={location.pathname.startsWith(item.path)}
                                onClick={() => {
                                    navigate(item.path);
                                    if (isMobile) onClose(); // close drawer on mobile after navigation
                                }}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={t(item.text)} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    );
};

export default MainDrawer;
