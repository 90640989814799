// src/app/assets/styles/GlobalStyles.tsx

import React from 'react';
import { CssBaseline, GlobalStyles as MuiGlobalStyles } from '@mui/material';

const GlobalStyles = () => (
    <>
        <CssBaseline />
        <MuiGlobalStyles
            styles={(theme) => ({
                html: {
                    width: '100%',
                    overflowX: 'hidden', // Prevent horizontal scrolling on mobile
                    WebkitOverflowScrolling: 'touch'
                },
                body: {
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    margin: 0,
                    padding: 0,
                    fontFamily: theme.typography.fontFamily,
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                    width: '100%',
                    overflowX: 'hidden', // Also prevent horizontal scroll on body
                },
                a: {
                    color: theme.palette.primary.main,
                    textDecoration: 'none',
                },
                /* Custom Scrollbar */
                '::-webkit-scrollbar': {
                    width: '8px',
                    height: '8px',
                },
                '::-webkit-scrollbar-track': {
                    background: theme.palette.background.paper,
                },
                '::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: '4px',
                },
                /* React Quill Styles */
                '.ql-container': {
                    backgroundColor: 'var(--editor-bg-color, #ffffff)',
                    color: 'var(--editor-text-color, #000000)',
                    border: 'none',
                    fontSize: '16px',
                    borderRadius: '4px',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                },
                '.ql-editor': {
                    minHeight: '150px',
                    padding: '10px',
                },
                '.ql-toolbar': {
                    border: 'none',
                    borderBottom: '1px solid var(--editor-border-color, #e0e0e0)',
                    backgroundColor: 'var(--editor-toolbar-bg-color, #f4f4f4)',
                },
                '.ql-toolbar .ql-stroke': {
                    stroke: 'var(--editor-toolbar-icon-color, #000000)',
                },
                '.ql-toolbar .ql-fill': {
                    fill: 'var(--editor-toolbar-icon-color, #000000)',
                }
            })}
        />
    </>
);

export default GlobalStyles;
